import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AboutPrincipal from "../components/AboutPrincipal";
import Topbar from "../components/Topbar";

const AboutPrincipalPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Principal Desk">
            <Topbar />
            <NavOne />
            <PageHeader title="Principal's Desk" />
            <AboutPrincipal />
            <Footer />
        </Layout>
    );
};

export default AboutPrincipalPage;
